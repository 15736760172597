<template>
  <div v-if="value" class="flex h-fit flex-wrap gap-3 lg:block">
    <Component
      :is="isLgAndDown ? UiButton : UiButtonLink"
      v-if="value.categoryId && getCategoryFromHandbook(value.categoryId)"
      tag="RouterLink"
      :severity="isLgAndDown ? 'secondary' : 'primary'"
      size="small"
      :icon="isLgAndUp"
      :to="{ name: 'CategoryItemView', params: { id: value.categoryId } }"
      class="flex items-center gap-1 rounded-lg !p-2 !text-sm"
    >
      {{ getCategoryFromHandbook(value.categoryId)!.name }}
      <UiIcon v-if="isLgAndDown" name="arrow-right" class="inline-flex size-3" />
    </Component>
    <Component
      :is="isLgAndDown ? UiButton : UiButtonLink"
      v-if="value.isPushkin"
      icon
      class="flex items-center gap-1 rounded-lg !p-2 !text-sm"
      severity="primary"
    >
      Пушкинская карта
    </Component>
  </div>
</template>

<script setup lang="ts">
import { useConfiguration } from '@/5_entities/Configuration'
import type { Event } from '@/5_entities/Event'
import { useBreakpoint } from '@/6_shared/lib'
import { UiButton, UiButtonLink, UiIcon } from '@/6_shared/ui'

type PropType = {
  value?: Event
}
withDefaults(defineProps<PropType>(), {
  value: undefined
})

const { getCategoryFromHandbook } = useConfiguration()
const {
  lg: [isLgAndUp, isLgAndDown]
} = useBreakpoint()
</script>
