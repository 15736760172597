<template>
  <section>
    <template v-if="!loading && value">
      <div>
        <UiTitle severity="h5">{{ value.title }}</UiTitle>
        <UiDescriptionAccordion
          v-if="value.description || defaultDescription"
          :max-height="isLgAndUp ? 448 : 146"
        >
          <div class="page-content text-sm" v-html="value.description || defaultDescription" />
          <template #gradient>
            <div
              :class="[
                'absolute left-0 top-0  size-full',
                'to-100 from-78% bg-gradient-to-b from-gradient-sixfold-first to-gradient-sixfold-second',
                'lg:from-gradient-quaternary-first lg:to-gradient-quaternary-second '
              ]"
            />
          </template>
        </UiDescriptionAccordion>
      </div>
    </template>
    <template v-else>
      <EventDescriptionSkeleton class="col-span-4 w-full" />
    </template>
  </section>
</template>

<script lang="ts" setup>
import { format } from 'date-fns'
import { ru } from 'date-fns/locale/ru'
import { computed } from 'vue'
import { type Event } from '@/5_entities/Event'
import { useBreakpoint } from '@/6_shared/lib'
import { UiDescriptionAccordion, UiTitle } from '@/6_shared/ui'
import EventDescriptionSkeleton from './EventDescriptionSkeleton.vue'

type PropType = {
  value?: Event
  loading?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  loading: false,
  value: undefined
})
const {
  lg: [isLgAndUp]
} = useBreakpoint()

const defaultDescription = computed(() => {
  if (!props.value || !props.value.seances?.length) return
  const firstSeance = props.value.seances[0]
  const addressString = firstSeance.venue?.address
    ? ` по адресу ${firstSeance.venue.address.normalized}`
    : ''

  return `Приходите на ближайшее мероприятие <b>${format(firstSeance.beginsAt, 'd')}</b> ${format(firstSeance.beginsAt, 'MMMM', { locale: ru })} в <b>${format(firstSeance.beginsAt, 'HH:mm')}</b>${addressString}, чтобы узнать больше`
})
</script>
